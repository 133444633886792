import { defineStore } from "pinia";
//import WordPressAPIService from "@/services/WordPressService";
import { storeToRefs } from "pinia";
import { useMarketingMaterialStore } from "./marketingMaterial";

export const useFileTypeStore = defineStore({
  id: "fileTypeStore",
  state: () => ({
    fileTypes: [],
    nestedFileTypes: [],
  }),
  getters: {
    getFileTypes: (state) => state.fileTypes,
    getFileTypeBySlug: (state) => (slug) =>
      state.fileTypes.find((fileType) => fileType.slug === slug),
    getFileTypesName: (state) => {
      let fileTypes = [];
      state.fileTypes.forEach((fileType) => fileTypes.push(fileType.name));
      return fileTypes;
    },
    getNestedGalleries: (state) => {
      return state.nestedFileTypes.find((element) => element.id_ap === "GIS"); // GIS = Gallery Images
    },
  },
  actions: {
    async init() {
      const { getMarketingMaterials } = storeToRefs(
        useMarketingMaterialStore()
      );
      this.fileTypes = JSON.parse(
        localStorage.getItem("fileTypeStore")
      ).fileTypes;
      //this.fileTypes = await WordPressAPIService.getAllPages('/tipo_recurso', {acf_format: 'standard',  _fields: 'name,slug,parent,id,acf'})
      this.fileTypes = this.fileTypes.filter(
        (fileType) => fileType.name !== "Marketing Material"
      );
      this.fileTypes = this.fileTypes.filter(
        (fileType) =>
          !getMarketingMaterials.value
            .map((marketingMaterial) => marketingMaterial.slug)
            .includes(fileType.slug)
      );
      //this.nestedFileTypes = await WordPressAPIService.getAllPages('/tipo_recurso_anidado', {})
      this.nestedFileTypes = JSON.parse(
        localStorage.getItem("fileTypeStore")
      ).nestedFileTypes;

      this.nestedFileTypes = Object.values(this.nestedFileTypes);

      this.nestedFileTypes.forEach(
        (fileType) => (fileType.children = Object.values(fileType.children))
      );

      this.nestedFileTypes = this.nestedFileTypes.filter(
        (fileType) => fileType.name !== "Marketing Material"
      );
    },
  },
});
