import { defineStore } from "pinia";
import axios from "@/services/AxiosBase";
import { useSettingStore } from "./settings";
import _ from "lodash";
import { useResourceStore } from "./resources";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import Cookies from "js-cookie";
dayjs.extend(customParseFormat);

export const useUserStore = defineStore({
  id: "userStore",
  state: () => ({
    user: {},
  }),
  getters: {
    getUser: (state) => state.user,
    language: (state) => {
      const { getLanguageByLocale } = useSettingStore();
      const language = getLanguageByLocale(state.user.locale);
      return language;
    },
    history: (state) => {
      const { getResourceById } = useResourceStore();
      let historial = state.user?.acf.historial;
      if (historial) {
        historial.forEach((element) => {
          if (element.recurso.ID)
            element.recurso = getResourceById(element.recurso.ID);
          return element;
        });

        historial.sort((a, b) => {
          let aDate = dayjs(a.fecha, "YYYY-MM-DD H:mm:ss");
          let bDate = dayjs(b.fecha, "YYYY-MM-DD H:mm:ss");
          return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
        });
      }
      return historial;
    },
    isPress: (state) => state.user.roles === "press",
  },
  actions: {
    async init() {
      //this.updateLanguage();
      try {
        const result = await axios.get(`/users/me`, {
          params: {
            acf_format: "standard",
            context: "edit",
            _fields:
              "first_name,last_name,acf,locale,roles,company,country,email",
          },
        });
        this.user = result.data;
        this.updateFields();
      } catch (error) {
        console.error(error);
      }
      //this.updateLanguage();
    },
    commit() {
      let data = _.omit(this.user, ["_links"]);
      data.acf = _.omit(data.acf, ["historial", "seleccion", "last_mod"]);
      data.user_roles = data.roles;
      if (data.country === "ESP") {
        data.acf = _.omit(data.acf, ["CAN_state", "USA_state"]);
      }
      if (data.country === "USA") {
        data.acf = _.omit(data.acf, ["CAN_state", "ESP_state"]);
      }
      if (data.country === "CAN") {
        data.acf = _.omit(data.acf, ["ESP_state", "USA_state"]);
      }
      if (!["USA", "ESP", "CAN"].includes(data.country)) {
        data.acf = _.omit(data.acf, ["ESP_state", "USA_state", "CAN_state"]);
      }
      const result = axios.post(`/users/me`, data, {
        params: { acf_format: "standard", context: "edit" },
      });
      result.then((response) => {
        this.user = response.data;
        this.updateFields();
        this.updateLanguage();
        setTimeout(() => this.$router.go(), 2000);
      });
      return result;
    },
    updateLanguage() {
      const language = this.user.locale.split("_")[0];

      Cookies.set("ap-change-language", true, {
        domain: ".viccarbe.com",
      });
      Cookies.set("area-profesionales-idioma", language, {
        domain: ".viccarbe.com",
      });

      this.$i18n.global.locale.value = language;
    },
    updateFields() {
      this.user.roles = this.user.roles[0];
      this.user.country = this.user.country.iso;
    },
  },
  persist: {
    enabled: true,
  },
});
