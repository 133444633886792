export default {
  "Architect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitecto"])},
  "autonomous-community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidad Autónoma"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por"])},
  "ByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Categoría"])},
  "ByFiletype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Tipo de Archivo"])},
  "ByMarketingMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material de Marketing"])},
  "Designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseñador"])},
  "Press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prensa"])},
  "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiante"])},
  "add-to-my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a mi selección"])},
  "added-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurso añadido"])},
  "back-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a inicio"])},
  "back-to-viccarbe-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la web de Viccarbe"])},
  "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificados"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
  "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
  "company-e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email corporativo"])},
  "copy-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar enlace"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "createNewProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Nuevo Proyecto"])},
  "download-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar todo"])},
  "download-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de Descargas"])},
  "download-history-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has descargado ningún archivo recientemente."])},
  "download-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar recurso"])},
  "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargando"])},
  "downloading-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargando"])},
  "error-renaming-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error renombrando proyecto"])},
  "error-saving-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error guardando proyecto"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivos"])},
  "filetypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de archivo"])},
  "filter-list-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los filtros seleccionados no coinciden."])},
  "filter-list-forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes permiso para acceder a estos archivos."])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "footer": {
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibe nuestra newsleter"])}
  },
  "galleries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerías"])},
  "gallery-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería de imagenes"])},
  "gallery-model-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay galerías disponibles para este modelo."])},
  "generating-share-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver atrás"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
  "how-to-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo usar"])},
  "image-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el formato de la imagen"])},
  "file3d-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el formato de 3D"])},
  "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síguenos"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
  "last-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página anterior"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "marketing-materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material de Marketing"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo"])},
  "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi Selección"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "new-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo proyecto"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
  "product-catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo del producto"])},
  "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesión"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "profileUpdated": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil actualizado"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu perfil ha sido actualizado correctamente."])}
  },
  "projectCreated": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado en"])}
  },
  "projectDeleted": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto eliminado"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El proyecto ha sido eliminado correctamente."])}
  },
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
  "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
  "remove-all-from-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar selección"])},
  "remove-from-my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar de mi selección"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar"])},
  "renamed-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrado como"])},
  "renameProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar Proyecto"])},
  "renaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrando"])},
  "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolución"])},
  "resource-modal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes seleccionar un formato de imagen"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado"])},
  "save-to-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar en proyecto"])},
  "saved-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado en"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardando"])},
  "saved-succesfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado correctamente"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "select": {
    "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron resultados"])}
  },
  "selection-item": {
    "toast": {
      "error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el error continua contacte con viccarbe.com"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error descargando el recurso"])}
      }
    }
  },
  "selection-list": {
    "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por tu descarga."])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has seleccionado ningún archivo aún."])},
    "toast": {
      "error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el error continua contacte con viccarbe.com"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error descargando la selección"])}
      }
    }
  },
  "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar email"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
  "shared-link": {
    "email": {
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace compartido"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola, te comparto este enlace de Viccarbe"])}
    }
  },
  "shared-link-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte tu selección"])},
  "single-project-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no has añadido recursos a este proyecto"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "taxes-and-costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los precios no incluyen tasas o costes de envío"])},
  "technical-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información técnica"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminos y Condiciones"])},
  "view-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
  "view-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "your-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus proyectos"])}
}