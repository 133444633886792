import axios from "axios";
import Cookies from "js-cookie";
import AxiosBase from "./AxiosBase";

export default class AuthService {
  user = () => JSON.parse(localStorage.getItem("user"));
  token = () => this.user()?.token;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_TOKEN,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      console.info("Login ingresado: ", username, "*".repeat(password.length));
      this.http
        .post("/token", { username, password })
        .then(({ data }) => {
          console.info("Usuario logueado: ", data.user_display_name);
          localStorage.setItem("user", JSON.stringify(data));
          // We must to update token because localStorage is not reactive
          AxiosBase.defaults.headers["Authorization"] = `Bearer ${data.token}`;
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  }

  logout() {
    console.log("Hacemos logout");
    localStorage.removeItem("user");
    sessionStorage.clear();
    localStorage.setItem("logout", "logout");
    Cookies.remove("user");
    if (process.env.NODE_ENV === "production")
      window.location.replace(process.env.VUE_APP_LOGOUT);
  }

  refreshToken() {}
}
