import { defineStore } from 'pinia'
//import WordPressAPIService from "@/services/WordPressService";


export const useCategoryStore = defineStore({
  id: 'categoryStore',
  state: () => ({
    categories: [],
  }),
  getters: {
    getCategories: state => state.categories,
    getCategoryBySlug: state =>
            slug => state.categories.find(category => category.slug === slug),
    getCategoryById: state => 
            id => state.categories.find(category => category.id === id),
    getCategoriesName: state => {
      let categories = [];
      state.categories.forEach(category => categories.push(category.name))
      return categories;
    },
  },
  actions: {
    init(){
      this.categories = JSON.parse(localStorage.getItem('categoryStore')).categories
      //this.categories = await WordPressAPIService.getAllPages('/product_tipo', {acf_format: 'standard'})
      //this.categories = this.categories.filter(element => element.acf.primaria === true)
    },
  }/* ,
  persist: {
    enabled: true
  } */
})