import { defineStore } from "pinia";
import WordPressAPIService from "@/services/WordPressService";
import axios from "@/services/AxiosBase";
import { useResourceStore } from "./resources";

export const useProjectStore = defineStore({
  id: "projectStore",
  state: () => ({
    projects: [],
  }),
  getters: {
    getProjects: (state) => state.projects,
    getProjectById: (state) => (projectId) =>
      state.projects.find((project) => project.id === projectId),
    getProjectBySlug: (state) => (projectSlug) =>
      state.projects.find((project) => project.slug === projectSlug),
    getProjectBySlugWithResources: (state) => (projectSlug) => {
      const project = JSON.parse(
        JSON.stringify(state.getProjectBySlug(projectSlug))
      );
      if (project) {
        const resourceStore = useResourceStore();
        const { getResourceById } = resourceStore;
        let resources = [];

        if (project.acf.recursos) {
          project.acf.recursos.forEach((recurso) => {
            const resource = getResourceById(recurso.ID);
            if (resource) resources.push(resource);
          });
        }

        project.resources = resources;
      }
      return project;
    },
    getThumbnail: (state) => (projectSlug) => {
      const resources =
        state.getProjectBySlugWithResources(projectSlug).resources;
      const resourceImages = resources
        .map((resource) => resource.acf.show_image)
        .filter((image) => image);
      const image = resourceImages.at(-1)?.sizes?.thumbnail;
      return image;
    },
  },
  actions: {
    async init() {
      const userId = WordPressAPIService.getUserId();
      this.projects = await WordPressAPIService.getAllPages(
        "/proyecto_recurso",
        { author: userId, _fields: "title,slug,id,acf", acf_format: "standard" }
      );
    },
    async addProject(project) {
      if (project.acf.imagen) {
        const imageResponse = await WordPressAPIService.uploadFile(
          project.acf.imagen
        );
        if (imageResponse.status >= 200 && imageResponse.status < 300)
          project.acf.imagen = imageResponse.data.id;
        else delete project.acf.imagen;
      }
      const result = await axios.post(
        "/proyecto_recurso/",
        { ...project, status: "publish" },
        { params: { acf_format: "standard" } }
      );
      await this.init();
      return result;
    },
    async deleteProject(projectId) {
      console.log(projectId);
      const result = await axios.delete(`/proyecto_recurso/${projectId}`, {
        params: { force: true },
      });
      await this.init();
      return result;
    },
    async renameProject(projectId, newName) {
      const result = await axios.put(`/proyecto_recurso/${projectId}`, {
        id: projectId,
        slug: newName,
        title: {raw: newName, rendered: newName}
      });
      await this.init();
      return result;
    },
    async addResource(projectId, resourceId) {
      let project = JSON.parse(JSON.stringify(this.getProjectById(projectId)));
      if (project) {
        if (!project.acf.recursos) project.acf.recursos = [resourceId];
        else {
          project.acf.recursos = project.acf.recursos.map(
            (element) => element.ID
          );
          project.acf.recursos.push(resourceId);
        }

        const result = await axios.post(
          `/proyecto_recurso/${projectId}`,
          { acf: { recursos: project.acf.recursos } },
          { params: { acf_format: "standard" } }
        );
        this.init();
        return result;
      }
      return undefined;
    },
    async removeResource(projectId, resourceId) {
      let project = JSON.parse(JSON.stringify(this.getProjectById(projectId)));
      if (project) {
        project.acf.recursos = project.acf.recursos.map(
          (element) => element.ID
        );
        project.acf.recursos = project.acf.recursos.filter(
          (element) => element !== resourceId
        );
        await axios
          .post(
            `/proyecto_recurso/${projectId}`,
            { acf: { recursos: project.acf.recursos } },
            { params: { acf_format: "standard" } }
          )
          .then(() => {
            // Remove resource from project
            this.projects = this.projects.map((project) => {
              if (project.id === projectId) {
                project.acf.recursos = project.acf.recursos.filter(
                  (resource) => resource.ID !== resourceId
                );
              }
              return project;
            });
          });
      }
    },
  },
  persist: {
    enabled: true,
  },
});
