import { defineStore } from 'pinia'
//import WordPressAPIService from "@/services/WordPressService";


export const useProductStore = defineStore({
  id: 'productStore',
  state: () => ({
    products: [],
    loading: false,
  }),
  getters: {
    getProducts: state => state.products,
    getProductBySlug: state =>
            slug => state.products.find(product => product.slug === slug),
    getProductById: state => 
            id => state.products.find(product => product.id === id),
    getProductsName: state => {
      let products = [];
      state.products.forEach(product => products.push(product.name))
      return products;
    },
  },
  actions: {
    async init(){
      this.loading = true;
      this.products = JSON.parse(localStorage.getItem('categoryStore')).categories
      //this.products = await WordPressAPIService.getAllPages('/product_tipo', {acf_format: 'standard'})
      //this.products = this.products.filter(element => element.acf.primaria === false)
      this.loading = false;
    },
  },
})