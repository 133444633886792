import axios from "axios";
import { debounce } from "lodash";

import Cookies from "js-cookie";

const userCookie = Cookies.get("user");
if (userCookie) {
  Cookies.remove("user");
  localStorage.setItem("user", userCookie);
}

const user = () => JSON.parse(localStorage.getItem("user"));
const token = () => user()?.token;

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_API_WORDPRESS,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token()}`,
  },
});

const requestInterceptor = (request) => {
  //Extract token from Bearer
  const token = request.headers.Authorization?.split(" ")[1];
  const parsedToken = JSON.parse(atob(token.split(".")[1]));
  const issuedAt = new Date(parsedToken.iat * 1000);
  // If issued at is more than 24 hours ago, we refresh token
  if (issuedAt < new Date(Date.now() - 24 * 60 * 60 * 1000)) {
    debouncedRefreshToken(request);
  }
  return request;
};

const responseInterceptor = (response) => {
  if (response.status > 299) {
    const code = response.data?.code;
    const codesList = [
      "jwt_auth_invalid_token",
      "jwt_auth_expired_token",
      "jwt_auth_no_token_found",
      "jwt_auth_bad_config",
    ];
    if (code && codesList.includes(code)) {
      console.log("Error token inválido, expirado o no encontrado");
      // Cerramos sesión y redirigimos a login
      localStorage.removeItem("user");
      sessionStorage.clear();
      localStorage.setItem("logout", "logout");
      if (process.env.NODE_ENV === "production")
        window.location.replace(process.env.VUE_APP_LOGOUT);
    }
  }
  return response;
};

axiosBase.interceptors.request.use(requestInterceptor);
axiosBase.interceptors.response.use(responseInterceptor);

export default axiosBase;

// This function will be called only once every 5 seconds.
const debouncedRefreshToken = debounce((request) => {
  const token = request.headers.Authorization?.split(" ")[1];
  console.log(
    "Han pasado más de 24 horas desde el último login. Renovamos token"
  );
  axios
    .get(`${process.env.VUE_APP_API_TOKEN}/token/refresh`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      console.log("Token renovado");
      const user = JSON.parse(localStorage.getItem("user"));
      user.token = data;
      localStorage.setItem("user", JSON.stringify(user));
      request.headers.Authorization = `Bearer ${data.token}`;
    })
    .catch((error) => {
      console.log("Error renovando token", error);
      // Cerramos sesión y redirigimos a login
      localStorage.removeItem("user");
      sessionStorage.clear();
      localStorage.setItem("logout", "logout");
      if (process.env.NODE_ENV === "production")
        window.location.replace(process.env.VUE_APP_LOGOUT);
    });
}, 5000);
