import axios from "@/services/AxiosBase";
import rawAxios from "axios";
import { event } from "vue-gtag";
import { useUserStore } from "@/store/user";
import { saveAs } from "file-saver";
import jwt_decode from "jwt-decode";
import { useResourceStore } from "@/store/resources";
import { storeToRefs } from "pinia";
import Cookies from "js-cookie";
import { BlobReader, ZipReader, BlobWriter, getMimeType } from "@zip.js/zip.js";

export default class WordPressAPIService {
  static user = () => JSON.parse(localStorage.getItem("user"));
  static token = () => this.user()?.token;
  static decodedToken = () =>
    this.token() ? jwt_decode(this.token()) : undefined;
  static getUserId = () => this.decodedToken()?.data?.user?.id;
  static isLoggedIn = () =>
    this.token() !== undefined && !this.isTokenExpired();
  static isTokenExpired = () =>
    Number(this.decodedToken()?.exp < new Date().getTime() / 1000);

  static async getAllPages(resource, params = { acf_format: "standard" }) {
    let data = [];

    try {
      let page = 1;
      let per_page = 100;
      let hasMorePages = false;

      params = { ...params, page, per_page };

      do {
        const result = await axios.get(resource, { params });
        data = data.concat(result.data);
        const postsOnThisPage = result.data.length;
        const totalPages = result.headers["x-wp-totalpages"];
        hasMorePages = postsOnThisPage > 0 && page < totalPages;
        page++;
      } while (hasMorePages);
    } catch (error) {
      console.error(error);
    }
    return data;
  }

  static async uploadFile(file) {
    let formData = new FormData();
    formData.append("file", file);
    const result = await axios.post("/media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  }

  static addResourcesToSelection(selection) {
    const ids = selection.map((element) => element.id);
    const options = selection.map((element) => {
      let data = { tipo: element.tipo };
      if (element.format && element.resolution) {
        data.formato = element.format;
        data.resolucion = element.resolution;
      }
      return data;
    });
    event("download", {
      resourcesId: ids,
      event_category: "downloads",
      event_label: "download-all",
    });
    return axios.post("/add_to_selection", { ids, options });
  }

  static async downloadZipOrFile( responseData ) {
    //
    const validExt = ['.pdf', '.jpg', '.tif', '.dwg', '.zip'];
    //
    const zipFileReader = new BlobReader(responseData);
    const zipReader = new ZipReader(zipFileReader);
    let numFiles = (await zipReader.getEntries()).length;
    if(numFiles > 1) {
      const blob = new Blob([responseData], { type: "application/x-zip" });
      saveAs(blob, "viccarbe.zip");
    } else if(numFiles === 1) {
      const entry = (await zipReader.getEntries())[0];
      if(validExt.some(e => entry.filename.toLowerCase().endsWith(e))) {
        const fileExt = entry.filename.split('.').pop();
        const mimeType = getMimeType(fileExt);
        var blobContent = new BlobWriter();
        await entry.getData(blobContent);
        const blob = new Blob([(await blobContent.getData())], {type: mimeType});
        saveAs(blob, entry.filename);
      } else {
        const blob = new Blob([responseData], { type: "application/x-zip" });
        saveAs(blob, "viccarbe.zip");
      }
    }
  }

  static async getSingleResourceURL(
    id,
    tipo,
    formato = undefined,
    resolucion = undefined
  ) {
    const { init: initUser } = useUserStore();
    const response = await axios.post(
      "/download_single",
      { id, tipo, formato, resolucion },
      {
        responseType: "blob",
        headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
      }
    );
    event("download", {
      resourceId: id,
      event_category: "downloads",
      event_label: "download-single",
    });
    initUser(); // We reinitialize user to update download history
    // const blob = new Blob([response.data], { type: "application/x-zip" });
    // saveAs(blob, "viccarbe.zip");
    WordPressAPIService.downloadZipOrFile(response.data);
  }

  static async getMultipleResourceURL(
    id,
    tipo,
    formatos = [],
    resoluciones = []
  ) {
    const { init: initUser } = useUserStore();
    const response = await axios.post(
      "/download_multiple",
      { id, tipo, formatos, resoluciones },
      {
        responseType: "blob",
        headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
      }
    );
    event("download", {
      resourceId: id,
      event_category: "downloads",
      event_label: "download-multiple",
    });
    initUser(); // We reinitialize user to update download history
    // const blob = new Blob([response.data], { type: "application/x-zip" });
    // saveAs(blob, "viccarbe.zip");
    WordPressAPIService.downloadZipOrFile(response.data);
  }

  static async downloadAllSelectedResources() {
    const { init: initUser } = useUserStore();
    const response = await axios.get("/download_selection?ts=" + Date.now(), {
      responseType: "blob",
      headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
    });
    initUser(); // We reinitialize user to update download history
    // const blob = new Blob([response.data], { type: "application/x-zip" });
    // saveAs(blob, "viccarbe.zip");
    WordPressAPIService.downloadZipOrFile(response.data);
  }
  static async getHomeGrid() {
    const response = await axios.get("/home_grid");
    return response.data;
  }

  static async getAllDataFromJson() {
    const { resources } = storeToRefs(useResourceStore());
    const language = Cookies.get("area-profesionales-idioma") || "en";
    const jsonName = `${process.env.VUE_APP_SITE}/rest/${language}.json`;
    const consoleMessage = `Obteniendo datos del json ${jsonName}...`;
    console.log(consoleMessage);
    return rawAxios.get(jsonName).then(({ data }) => {
      const {
        categoryStore,
        fileTypeStore,
        homeStore,
        marketingMaterialStore,
        modelStore,
        productStore,
        projectStore,
        resourceStore,
        settingsStore,
      } = data;
      console.log("setting data in localstorage");
      localStorage.setItem("categoryStore", JSON.stringify(categoryStore));
      localStorage.setItem("fileTypeStore", JSON.stringify(fileTypeStore));
      localStorage.setItem("homeStore", JSON.stringify(homeStore));
      localStorage.setItem(
        "marketingMaterialStore",
        JSON.stringify(marketingMaterialStore)
      );
      localStorage.setItem("modelStore", JSON.stringify(modelStore));
      localStorage.setItem("productStore", JSON.stringify(productStore));
      localStorage.setItem("projectStore", JSON.stringify(projectStore));
      // localStorage.setItem('resourceStore', JSON.stringify(resourceStore));
      resources.value = resourceStore.resources;
      console.log(`Size of resources: ${resources.value.length} items`);
      localStorage.setItem("settingsStore", JSON.stringify(settingsStore));
    });
  }

  static async generateShareLink() {
    const { init: initUser } = useUserStore();
    const response = await axios.post("/descarga_compartida");
    initUser(); // We reinitialize user to update download history
    return response.data;
  }
}
