import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from "./store";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue-skeletor/dist/vue-skeletor.css";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import filters from "@/helpers/filters";
import { Skeletor } from "vue-skeletor";
import Cookies from "js-cookie";
import initializeStores from "@/store/init";

const user = Cookies.get("user");
if (user) {
  Cookies.remove("user");
  localStorage.setItem("user", user);
}
const app = createApp(App);
const toastConfig = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_CENTER,
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  rtl: false,
  toastClassName: "viccarbe-style",
};
const gtagConfig = {
  config: {
    id: process.env.VUE_APP_GTAG_ID,
  },
};

app
  .use(i18n) // Must be the first one. ¡ALWAYS!
  .use(pinia({ router, i18n }))
  .use(router)
  .use(Toast, toastConfig)
  .use(VueGtag, gtagConfig);

import WordPressService from "@/services/WordPressService";
if (WordPressService.isLoggedIn()) await WordPressService.getAllDataFromJson();
initializeStores();

app.component(Skeletor.name, Skeletor);

app.mount("#app");

app.config.globalProperties.$env = process.env; // Allow to use environment vars from Options API components
app.config.globalProperties.$filters = filters; // Allow to use filters from Options API components
