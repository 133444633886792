<template>
    <div class="wrapper">
        <!-- <div>Trav. Camí el Racó 23</div>
        <div>P.I. Norte Beniparrell</div>
        <div>46469 Valencia - Spain</div> -->

        <a :href="newsletterLink" target="_blank" class="newsletter">
            {{ $t('footer.newsletter') }}
        </a>

        <div class="social-icons">
            <a :href="linkedinLink" target="_blank">
                <img src="@/assets/icons/social/linkedin.svg" alt="linkedin">
            </a>
            <a :href="pinterestLink" target="_blank">
                <img src="@/assets/icons/social/pinterest.svg" alt="pinterest">
            </a>
            <a :href="tiktokLink" target="_blank">
                <img src="@/assets/icons/social/tiktok.svg" alt="tiktok">
            </a>
            <a :href="instagramLink" target="_blank">
                <img src="@/assets/icons/social/instagram.svg" alt="instagram">
            </a>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import Cookies from "js-cookie";
const linkedinLink = ref('https://es.linkedin.com/company/viccarbe-habitat-s-l-');
const pinterestLink = ref('https://www.pinterest.es/viccarbe/');
const tiktokLink = ref('https://www.tiktok.com/@viccarbe');
const instagramLink = ref('https://www.instagram.com/viccarbe/');
let language = Cookies.get("area-profesionales-idioma") || "eng";
if (language === 'en') language = 'eng';
const newsletterLink = ref('https://www.viccarbe.com/' + language + '/viccarbe-newsletter/');
</script>

<style scoped lang="scss">
.wrapper{
    background-color: $corporate-light-color;
    font-family: 'Tw Cen MT';
    color: $mid-grey-color;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    font-weight: 300;
    @include tracking(100);
    height: 110px;
    
    padding: 40px min(10%, 70px) 40px min(10%, 70px);
    
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.newsletter{
    text-transform: uppercase;
    font-size: 0.875rem;
}

.social-icons{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    img{
        width: 25px;
    }
}
</style>
