import { defineStore } from 'pinia'
//import WordPressAPIService from "@/services/WordPressService";


export const useMarketingMaterialStore = defineStore({
    id: 'marketingMaterialStore',
    state: () => ({
        marketingMaterials: [],
    }),
    getters: {
        getMarketingMaterials: state => state.marketingMaterials,
        getMarketingMaterialBySlug: state =>
            slug => state.marketingMaterials.find(marketingMaterial => marketingMaterial.slug === slug),
        getMarketingMaterialsName: state => {
            let marketingMaterials = [];
            state.marketingMaterials.forEach(marketingMaterial => marketingMaterials.push(marketingMaterial.name))
            return marketingMaterials;
        },
    },
    actions: {
        async init(){
            this.marketingMaterials = JSON.parse(localStorage.getItem('marketingMaterialStore')).marketingMaterials
            //this.marketingMaterials = await WordPressAPIService.getAllPages('/tipo_recurso', {acf_format: 'standard',  _fields: 'name,slug,parent,id,acf', parent: 711})	
        },
    },
    persist:{
        enabled: true
    }
})