<template>
  <div class="search" @click="$refs.searchInput.focus()">
    <div class="magnificient-glass-wrapper">
      <MagnificientGlassIcon class="magnificient-glass"/>
    </div>
    <input
        type="text"
        ref="searchInput"
        :placeholder="$t('search')"
        v-model="searchText"
        @keypress.enter="addTerm"
        autocomplete="false"
    >
    <div :class="['terms', {active: $route.params.terms}]">
      <span class="terms-item" v-for="(term, index) in $route.params.terms" :key="index">{{term.replaceAll('-', ' ')}} <CloseIcon @click="removeTerm(index)" class="close-icon"/> </span>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../icons/CloseIcon.vue';
import MagnificientGlassIcon from '../icons/MagnificientGlassIcon.vue';
export default {
    name: "SearchForm",
    data() {
        return ({
            searchText: "",
        });
    },
    methods: {
        addTerm() {
            if (this.searchText.length > 0) {
                if (this.$route.name === "search" && this.$route.params.terms.length > 0) {
                    this.$router.push({
                        name: "search",
                        params: {
                            terms: [...this.$route.params.terms, this.searchText.replaceAll(' ', '-')],
                        },
                    });
                }
                else {
                    this.$router.push({ name: "search", params: { terms: [this.searchText.replaceAll(' ', '-')] } });
                    this.$router.forward();
                }
                this.searchText = "";
            }
        },
        removeTerm(index) {
            // We must to use destructuring because we are using a ref and url doesn't update so.
            let terms = [...this.$router.currentRoute.value.params.terms];
            terms.splice(index, 1);
            if (terms.length > 0)
                this.$router.push({ params: { terms } });
            else
                this.$router.push({ name: "home" });
        },
    },
    components: { CloseIcon, MagnificientGlassIcon }
}
</script>

<style scoped lang="scss">
.search{
  position: relative;
}
input{
  box-sizing: border-box;
  margin-top: 20px;
  text-align: left;
  height: 45px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: transparent;
  border: 0.5px solid $mid-grey-color;
  width: 100%;
  @include twcen-light();
  font-family: 'Futura Lt BT';
  font-size: 0.8rem;
  &:focus{
    outline: none;
    border-width: 1px;
  }
}
.terms{
  box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: transparent;
  border: 0.5px solid $mid-grey-color;
  border-top: 0;
  width: 100%;
  opacity: 0;
  transition: 0.3s;
  &.active{
    opacity: 1;
  }
  &-item{
    display: inline-block;
    position: relative;
    font-family: 'Tw Cen MT';
    font-style: italic;
    font-weight: bold;
    color: $mid-grey-color;
    font-size: 1.0625rem;
    padding: 5px;
    
    margin-right: 20px;
    
  }
}
.close-icon{
  position: absolute;
  width: 23px;
  cursor: pointer;
}

.magnificient-glass-wrapper{
  cursor: pointer;
  height: 45px;
  position: absolute;
  top: 0px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.magnificient-glass{
  width: 20px;
  padding-left: 15px;
}

@include for-phone-only(){
  input {
    border-width: 0 0 0.5px 0;
    width: 50%;
    &:focus{
      border-width:  0 0 1px 0;
    }
    padding-left: 0;
    padding-right: 2.5rem;
  }
  .terms{
    width: 50%;
  }
  .magnificient-glass-wrapper{
    left: 38%;
  }
}

</style>