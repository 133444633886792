<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.47 4.56" :class="['icon', {...cssClass}]">
    <g id="a" />
    <g id="b">
        <g id="c">
            <polyline class="d" points="8.29 .18 6.26 2.19 4.23 4.21 2.2 2.19 .18 .18" />
        </g>
    </g>
</svg>
</template>

<script setup>
import { computed } from '@vue/reactivity';

const props = defineProps({
    reverse: {
        type: Boolean,
        default: false,
    },
    position: {
        type: String,
    }
})
const cssClass = computed(()=> {
    return {
        'icon-reverse': props.reverse,
        'icon-left': props.position === 'left' && !props.reverse,
        'icon-right': props.position === 'right' && !props.reverse,
    }
})
</script>

<style scoped>
.d {
    fill: none;
    stroke: #706f6f;
    stroke-miterlimit: 10;
    stroke-width: .5px;
}
svg{
    width: 0.5rem;
    height: 100%;
}
.icon{
    transition: 0.3s;
}
.icon-reverse{
    transform: rotate(180deg);
}
.icon-left{
    transform: rotate(90deg);
}
.icon-right{
    transform: rotate(270deg);
}
</style>