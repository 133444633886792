<template>
    <ul v-if="isLoggedIn()">
        <li>
            <RouterLink to="/">{{$t('home')}}</RouterLink>
        </li>
        <li>
            <RouterLink to="/account">{{$t('account')}}</RouterLink>
        </li>
        <li>
            <RouterLink to="/projects">{{$t('projects')}}</RouterLink>
        </li>
        <li>
            <RouterLink to="/my-selection">
                <span>{{$t('my-selection')}}</span>
                <div class="items-counter">{{count}}</div>
            </RouterLink>
        </li>
        <li v-if="isLoggedIn()">
            <a href="#" @click="logout()">{{$t('logout')}}</a>
        </li>
        <li v-else>
            <RouterLink to="/login">{{$t('login')}}</RouterLink>
        </li>
        <li class="back-web">
            <a href="https://viccarbe.com/">&#60; {{ $t('back-to-viccarbe-website') }}</a>
        </li>
    </ul>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useResourceStore } from '@/store/resources';
import AuthService from '@/services/AuthService';

const { countSelectedResources: count } = storeToRefs(useResourceStore())
const authService = new AuthService();

const isLoggedIn = () => authService.token() !== undefined
const logout = () => authService.logout()
</script>

<style scoped lang="scss">
ul {
    width: 100%;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;

    li {
        // We need relative position for the counter to be displayed correctly in My Selection
        position: relative;
        margin-left: min(5%, 25px);
    }
}

@include for-phone-only() {
    ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;

        li {
            margin-left: 0;
            margin-top: 0px;
        }
    }
}

.items-counter {
    // position: absolute;
    // background-color: $corporate-primary-color;
    // width: 1.2rem;
    // height: 1.2rem;
    // border-radius: 50%;
    // font-size: 0.96rem;
    // line-height: 1.5rem;

    // &::first-letter{
    //     letter-spacing: 0;
    // }
    // top: -1rem;
    // right: -1rem;
    // text-align: center;
    font-family: "Futura Lt BT";
    font-size: 0.7rem;
    color: $mid-grey-color;
    font-weight: 300;
    line-height: 1.2rem;

    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: $corporate-primary-color;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    // padding: 0.1rem;
    // padding-bottom: 0.2rem;

    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;


}

.back-web {
    a::first-letter {
        font-size: 1rem;
    }

    display: none;

    @include for-phone-only() {
        display: inline;
    }
}
</style>