export default {
  "Architect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architect"])},
  "autonomous-community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous Community"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
  "ByCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Category"])},
  "ByFiletype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Filetype"])},
  "ByMarketingMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing Material"])},
  "Designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer"])},
  "Press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
  "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "add-to-my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to my selection"])},
  "added-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added resource"])},
  "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
  "back-to-viccarbe-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Viccarbe website"])},
  "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "company-e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company e-mail"])},
  "copy-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "createNewProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Project"])},
  "download-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all"])},
  "download-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download History"])},
  "download-history-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any files in the download history."])},
  "download-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download resource"])},
  "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading"])},
  "downloading-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading"])},
  "error-renaming-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error renaming project"])},
  "error-saving-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving project"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
  "filetypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filetypes"])},
  "filter-list-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected filters do not coincide."])},
  "filter-list-forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to access this files."])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "footer": {
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to our Newsleter"])}
  },
  "galleries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleries"])},
  "gallery-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery images"])},
  "gallery-model-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no galleries available for this model."])},
  "generating-share-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "how-to-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
  "image-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick the image format"])},
  "file3d-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick the 3D format"])},
  "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "last-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last page"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "marketing-materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing Materials"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Selection"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "new-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New project"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "product-catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product catalogue"])},
  "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profileUpdated": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been updated successfully."])}
  },
  "projectCreated": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado en"])}
  },
  "projectDeleted": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project deleted"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project has been deleted successfully."])}
  },
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
  "remove-all-from-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all from selection"])},
  "remove-from-my-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from my selection"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "renamed-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renamed as"])},
  "renameProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename Project"])},
  "renaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renaming"])},
  "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution"])},
  "resource-modal": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select image format"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "save-to-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to project"])},
  "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "saved-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved in"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
  "saved-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved successfully"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "select": {
    "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])}
  },
  "selection-item": {
    "toast": {
      "error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If error persists contact with viccarbe.com"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error downloading item"])}
      }
    }
  },
  "selection-list": {
    "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your download."])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not selected any files."])},
    "toast": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error downloading selection"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If error persists contact with viccarbe.com"])}
      }
    }
  },
  "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "shared-link": {
    "email": {
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared link"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I have shared a link with you. Click on the link to access the content: "])}
    }
  },
  "shared-link-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your selection"])},
  "single-project-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added resources to this project yet"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "taxes-and-costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices do not include taxes or shipping costs"])},
  "technical-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical info"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "view-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View less"])},
  "view-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])},
  "your-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your projects"])}
}