<template>
    <router-view />
</template>


<script async setup>

</script>

<style lang="scss">
@import './assets/css/main.scss';
</style>