<template>
    <Teleport to="body">
        <nav :class="{opened: menuOpened}">
            <div :class="{actions: true, opened: menuOpened}">
                <div class="toggle-btn-clickable-area" :class="{'menu-opened': menuOpened}" @click="toggle">
                    <div :class="{'toggle-menu-btn': true, 'opened': menuOpened}"></div>
                </div>
                <div class="secondary-actions" v-show="!menuOpened">
                    <router-link to="/account">
                        <UserIcon />
                    </router-link>

                    <!-- <MagnificientGlassIcon /> -->
                </div>
            </div>

            <div class="menu" v-show="menuOpened">
                <div>
                    <FilterDropdown @toggle="toggleFilter($event)" v-if="menuOpened" />
                    <Search v-if="!filtersOpen" class="search" />
                </div>
                <NavItems v-if="!filtersOpen" />
                <DefaultButton @click="toggle" v-if="filtersOpen">View files</DefaultButton>
            </div>
        </nav>
    </Teleport>

</template>

<script setup>
import { ref } from 'vue'
import NavItems from "./NavItems.vue"
import Search from "./Search.vue"
import DefaultButton from '@/components/common/DefaultButton.vue'
import FilterDropdown from "@/components/common/FilterDropdown"
import UserIcon from "../icons/UserIcon.vue"
// import MagnificientGlassIcon from "../icons/MagnificientGlassIcon.vue"
import { onBeforeRouteUpdate } from 'vue-router'

const menuOpened = ref(false)
const filtersOpen = ref(false)

const toggle = () => {
    menuOpened.value = !menuOpened.value
    if (menuOpened.value) {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'auto';
        toggleFilter(false);
    }
}

const toggleFilter = (isOpen) => {
    filtersOpen.value = isOpen
}


onBeforeRouteUpdate((to, _from, next) => {
    const routes = ['home', 'account', 'my-selection']

    if (menuOpened.value && routes.includes(to.name)) {
        toggle()
    }
    next()
})
</script>

<style scoped lang="scss">
nav {
    overflow: auto;

    @include for-tablet-up() {
        display: none;
    }

    width: 100%;
    height: 7.4vh;
    display: grid;
    grid-template-areas: "actions";
    align-items: center;
    position: fixed;
    z-index: 2;
    bottom: 0;
    background-color: $corporate-light-color;
    padding: 0 min(10%, 70px) 0 min(10%, 70px);
    box-sizing: border-box;
    transition: height 1s,
    bottom 5s;

    &>.actions {
        height: 7.4vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-area: actions;

        &.opened {
            justify-content: flex-end;
        }

        &>.toggle-btn-clickable-area {
            height: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &>.toggle-menu-btn {
                width: 56px;
                height: 8px;
                position: relative;
                display: flex;
                justify-content: center;
                cursor: pointer;

                &.opened {
                    height: 0px;

                    &::after {
                        width: 50%;
                        transform: rotate(45deg);
                    }

                    &::before {
                        width: 50%;
                        transform: rotate(-45deg);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    height: 0.03rem;
                    width: 100%;
                    top: 0;
                    background-color: $mid-grey-color;
                    // transition: 1.3s;

                }

                &::after {
                    content: '';
                    position: absolute;
                    height: 0.03rem;
                    width: 100%;
                    bottom: 0;
                    background-color: $mid-grey-color;
                    //transition: 1.3s;
                }
            }
        }

        &>.secondary-actions {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;

            &>* {
                display: flex;
                width: 1.25rem;
                cursor: pointer;
            }
        }
    }

    & .menu {
        grid-area: menu;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 3rem;
        box-sizing: border-box;
        height: 100%;

        &-opened {
            position: absolute;
            right: 0;
        }


    }

    &.opened {
        grid-template-areas: "actions"
            "menu";
        grid-template-rows: auto 1fr;
        align-items: start;
        row-gap: 20px;
        height: calc(100vh - var(--navbar-height));
    }
}
</style>

<style scoped>
.search:deep(*) {
    margin-top: 0;
}
</style>