import { createPinia } from 'pinia'
import { markRaw } from "vue";
import piniaPersist from 'pinia-plugin-persist'



export default function pinia(plugins){
    const pinia = createPinia();

    // Add local plugins to array
    pinia.use(piniaPersist)

    for(let plugin in plugins){
        pinia.use(({ store }) => {
            store[`$${plugin}`] = markRaw(plugins[plugin]);
        })
    }
    return pinia;
}