import { defineStore, storeToRefs } from 'pinia'
import { useProductStore } from "@/store/products";
import { useResourceStore } from './resources';


export const useModelStore = defineStore({
    id: 'modelStore',
    state: () => ({
        models: [],
        loading: false,
    }),
    getters: {
        getModels: state => state.models,
        getModelByProductSlug: state =>
            productSlug => {
                const productStore = useProductStore();
                const product = productStore.getProductBySlug(productSlug);
                if (product !== undefined) {
                    const id = product.id;
                    return state.models.filter(product => product.product_tipo.includes(id))
                }
            },
        getModelsFromActiveFilters: state => {
            const { getProductBySlug, getProducts } = useProductStore()
            const currentParams = state.$router.currentRoute.value.params

            //let activeProducts = currentParams?.products
            const hasKeys = !!Object.keys(currentParams).length;
            let activeCategories = hasKeys ? currentParams?.categories : getProducts.map(o => o.slug)
            let filteredModels = state.models
            if (activeCategories) {
                //Map category slug for swap by product id
                activeCategories = activeCategories.map(slug => getProductBySlug(slug)?.id).filter(element => element !== undefined)
                // Filtering resources by category id
                filteredModels = filteredModels.filter(model => model.product_tipo.some(
                    modelProduct => activeCategories?.includes(modelProduct)
                ));
            }

            let sortedModels = filteredModels.sort(
                (a, b) => {
                    if (a.title.rendered > b.title.rendered) return 1
                    if (a.title.rendered < b.title.rendered) return -1
                    return 0;
                }
            )

            sortedModels = sortedModels.sort(
                (a, b) => {
                    if (a.product_tipo_slug > b.product_tipo_slug) return 1
                    if (a.product_tipo_slug < b.product_tipo_slug) return -1
                    return 0;
                }
            )
            return sortedModels;
        },
        getModelBySlug: state =>
            modelSlug => state.models.find(model => model.slug === modelSlug),
        getGalleriesByModelSlug: () =>
            modelSlug => useResourceStore().getGalleryFromModel(modelSlug),

    },
    actions: {
        async init() {
            this.loading = true;
            //this.models = await WordPressAPIService.getAllPages('/modelo', {acf_format: 'standard'})
            this.models = JSON.parse(localStorage.getItem('modelStore')).models
            const { products } = storeToRefs(useProductStore())
            this.models = this.models.map(model => {
                let product_tipo_slug = products.value.find(product => product.id === model.product_tipo[0])
                model.product_tipo_slug = product_tipo_slug?.slug
                return model
            })
            this.loading = false;
        }
    },
})