import { defineStore } from "pinia";
//import WordPressAPIService from "@/services/WordPressService";

export const useSettingStore = defineStore("settingsStore", {
  state: () => ({
    countries: [],
    languages: {},
    currentLanguage: undefined,
    pageTitle: undefined,
    defaultImages: undefined,
    states: {},
  }),
  getters: {
    getLanguageByLocale: (state) => (locale) =>
      Object.values(state.languages).find(
        (language) => language.default_locale === locale
      ),
  },
  actions: {
    async init() {
      const { countries, languages, currentLanguage, states, defaultImages } =
        localStorage.getItem("settingsStore")
          ? JSON.parse(localStorage.getItem("settingsStore"))
          : { countries: [], languages: {}, currentLanguage: undefined };
      this.languages = languages;
      this.countries = countries;
      this.currentLanguage = currentLanguage;
      this.states = states;
      this.defaultImages = defaultImages;
      //this.languages = (await WordPressAPIService.getAllPages('/active_languages'))[0]
      //this.countries = await WordPressAPIService.getAllPages('/countries')
    },
    setPageTitle(pageTitle) {
      if (
        typeof pageTitle === "string" ||
        Array.isArray(pageTitle) ||
        typeof pageTitle == undefined
      )
        this.pageTitle = pageTitle;
    },
  },
  /* persist: {
        enabled: true
    } */
});
