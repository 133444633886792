import { defineStore } from 'pinia'
//import WordPressAPIService from "@/services/WordPressService";


export const useHomeStore = defineStore({
  id: 'homeStore',
  state: () => ({
    grid: [],
    tour: [],
    loading: false,
    isOpenTour: false,
  }),
  getters: {
  },
  actions: {
    async init(){
      this.loading = true;
      this.grid = JSON.parse(localStorage.getItem('homeStore')).grid
      this.tour = JSON.parse(localStorage.getItem('homeStore')).tour
      //this.grid = await WordPressAPIService.getHomeGrid();
      this.loading = false;
    },
    closeTour(){
      this.isOpenTour = false;
    },
    openTour(){
      this.isOpenTour = true;
    }
  },
  persist: {
    enabled: true
  }
})