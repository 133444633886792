<template>
    <div class="filter-section">
        <div class="title" @click="showItems = !showItems">
            <ArrowIcon v-if="isMobile && showItems" position="left" />
            <span>
                {{$t(title)}}
            </span>
            <ArrowIcon v-if="!isMobile || !showItems" :reverse="showItems" />

        </div>
        <ul :class="['filter-options', {hide: !showItems}]">
            <template v-if="items && items.length > 0">
                <li v-for="item in items" :key="item.id" :class="{disabled: isDisabled(item)}" class="fila">
                    <input type="checkbox" :id="`item_input_${item.id}`" @change="toggle(item)"
                        :checked="isActive(item)" :disabled="isDisabled(item)" class="raiz">
                    <label :for="`item_input_${item.id}`">
                        <span class="input-with-children" v-if="item.children?.length > 0"
                            @click.prevent="toggleChildren(item.id)">
                            <span>{{item.name}}</span>
                            <ArrowIcon :reverse="showChildren(item.id)" />
                        </span>
                        <span v-else>{{item.name}}</span>
                    </label>
                    <template v-if="item.children?.length > 0">
                        <ul class="filter-options filter-children grupo" :class="{hide: !showChildren(item.id)}">
                            <li v-for="child in item.children" :key="child.term_id">
                                <input type="checkbox" :id="`item_input_${child.id}`" @change="toggle(child, item)"
                                    :checked="isActive(child)">
                                <label :for="`item_input_${child.id}`">
                                    {{child.name}}
                                </label>
                            </li>
                        </ul>
                    </template>
                </li>
            </template>
            <FilterSkeleton v-else :repeat="expectedItems" />
        </ul>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import filters from '@/helpers/filters'
import FilterSkeleton from './FilterSkeleton.vue';
import ArrowIcon from '@/components/icons/ArrowIcon.vue';
import { useScreen } from 'vue-screen';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';


const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    expectedItems: {
        type: Number,
        required: true
    }
})

const router = useRouter()
const route = useRoute()
const screen = useScreen()
const userStore = useUserStore()
const { isPress } = storeToRefs(userStore)
let showItems = ref(false)
const activeItems = computed(() => route.params[props.type])
const isActive = (item) => activeItems?.value ? activeItems.value.find(activeItem => activeItem === item.slug) : false
const allowedParams = ['marketingMaterials', 'fileTypes', 'categories']
const nodeChildren = ref([])
const itemsLength = computed(() => (props.items.length > 0) ? props.items.length : props.expectedItems)
const isMobile = computed(() => screen.width <= 687)

const disabledTypes = ['2D Files', '3D / REVIT / BIM files'];
const isDisabled = computed(() => (item) => props.type === 'fileTypes' && disabledTypes.includes(item.name) && isPress.value)
function showChildren(id) {
    if( id in nodeChildren.value) {
        return nodeChildren.value[id]
    }else {
        return nodeChildren.value[id] = false
    }
}
function toggle(item, father = null) {
    /*  
        ¡MUY IMPORTANTE!: Debemos clonar los parametros. Si no se actualiza la referencia de los 
        parámetros y los cambios no se reflejan en la url porque vue detecta que es la misma. 
    */
    const params = structuredClone(route.params);
    const slug = item.slug;
    const type = props.type;

    params[type] = params[type] || [];
    const index = params[type].indexOf(slug);
    
    if (index > -1) {
        params[type] = deactivate(item, params);
        if(father !== null) {
            params[type] = deactivate(father, params, true);
        }
    } else {
        params[type] = activate(item, params)
    }
    const isModels = route.path == '/' || route.path.startsWith('/models') 
    computeRoute(isModels, params)
}

const toggleChildren = (id) => nodeChildren.value[id] = !showChildren(id)

function computeRoute(isModels, params) {
    let path = ''
    const arr = ['gallery-images', 'galeria', 'white-image', 'blanco', 'environmental-image', 'entorno']
    if( isModels && params['fileTypes'] && params['fileTypes'].length > 0 && arr.includes(params['fileTypes'][0])) {
        path = '/models/file-types/' + `${params['fileTypes'].join('/')}`
    } else {
        const sortedFilters = ['categories', 'fileTypes', 'marketingMaterials']
        const keys = Object.keys(params).sort((a, b) => sortedFilters.indexOf(a) - sortedFilters.indexOf(b))
        for (let param of keys) {
            /*  
                Necesitamos filtrar por parámetros permitidos porque de lo contrario incluiría parámetros de
                otras rutas en esta y el sistema buscaría filtros que satisfagan esos parámetros.
            */
            if (params[param].length > 0 && allowedParams.includes(param))
                /*
                    Ordenamos los parametros para evitar crear urls duplicadas con diferente orden.
                    
                    Convertimos el nombre del parámetro a kebab-case, porque por convenio las urls
                    están especificadas en kebab-case pero los parámetros en camelCase. Dado que usamos
                    el nombre del parámetro como prefijo para cada sección, necesitamos parsear el nombre
                    del parámetro.
                */
                path += `/${filters.camelToKebabCase(param)}/${params[param].join('/')}`
        }
    }
    if (path === '') path = '/'
    /*  
        Usamos replace en lugar de push porque no nos interesa que se añada al historial de navegación 
        cada vez que se actualiza un filtro.
    */
    router.replace({ path })
}

function activate(item, params) {
    const slug = item.slug;
    const type = props.type;
    params[type] = params[type] || []
    const index = params[type]?.indexOf(slug);
    if (index === -1) {
        params[type].push(slug)
        if (item.children?.length > 0) {
            item.children.forEach(child => params[type] = activate(child, params))
        }
    }
    return params[type];
}
function deactivate(item, params, onlyFather = false) {
    const slug = item.slug;
    const type = props.type;
    const index = params[type]?.indexOf(slug);
    if (index > -1) {
        params[type].splice(index, 1);
        if (!onlyFather && item.children?.length > 0) {
            item.children.forEach(child => params[type] = deactivate(child, params))
        }
    }
    return params[type];
}

const emit = defineEmits(['toggle'])
watch(() => showItems.value, () => {
    emit('toggle', showItems.value)
})

</script>


<style scoped lang="scss">
.input-with-children {
    display: inline-flex;
    column-gap: 0.4rem;
    align-items: center;
}

.filter-section {
    border-top: 0.5px solid rgba(96, 96, 96, 0.7);
    @include tracking(50);

    font-family: 'Tw Cen MT';
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.75rem;
    height: auto;
    color: $mid-grey-color;
    transition: 0.3s;


    &>ul {
        // label span{
        //     text-transform: lowercase;
        //     &::first-letter, &.input-with-children span::first-letter{
        //         text-transform: capitalize;
        //     }
        // }
        padding-bottom: 20px;
        list-style: none;
    }

    &:hover {
        background: $corporate-light-color;
        cursor: pointer;
    }

    &.expanded {
        background: $corporate-light-color;
    }

    .filter-options {
        transition: 0.4s;

        @include for-phone-only() {
            transition: 0s;
        }

        transition-timing-function: linear;
        max-height: calc(v-bind(itemsLength) * 40px); // You must move this param to adjust transition time effect.
        opacity: 1;
        margin: 0;

        &.hide {
            max-height: 0;
            opacity: 0;
            padding-bottom: 0;
            pointer-events: none; //This prevent unintended clicks on checkboxes    
        }
    }

    &>.title {
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 0.4rem;

        svg {
            width: 0.75rem;
        }

        @include for-phone-only() {
            align-items: flex-start;

            svg {
                width: 0.5rem;

                &:last-child {
                    padding-top: 10px;
                }

                &:first-child {
                    padding-left: 20px;
                }
            }
        }

    }

    &:last-child {
        border-bottom: 0.5px solid rgba(96, 96, 96, 0.7);
    }

    .icon-arrow {
        width: 0.75rem;
        font-size: 0.5rem;
        color: #968A7E;
        opacity: 70%;
        transition: 0.5s;

        &::before {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &.open {
            transform: rotate(180deg);
        }
    }
}

ul {
    text-decoration: none;
    list-style: none;

    &.filter-options {
        padding: 0 20px 20px;

        @include for-phone-only() {
            padding: 0 39px 20px;
        }

        li.disabled {
            color: #969696;

            label {
                cursor: default;

                &::before {
                    border: 0.063rem solid #969696;
                }
            }
        }
    }

    &.filter-children {
        padding: 0;
        padding-left: 20px;
    }
}

@include for-phone-only() {
    .filter-section {
        border: none;

        &:last-child {
            border-bottom: none;
        }

        .title {
            padding: .3rem;
            justify-content: flex-start;
            gap: .3rem;

            .icon-arrow {
                font-size: .4rem;
            }
        }
    }
}

.fila {
    line-height: 15px;
    margin-bottom: 10px !important;
}
.grupo {
    line-height: 25px;
}
.grupo li:first-child {
    padding-top: 8px;
}

input.raiz[type=checkbox]+label::before {
    align-self: flex-start;
}
</style>