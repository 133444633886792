<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.76 19.03">
    <g id="a" />
    <g id="b">
        <g id="c">
            <g>
                <circle class="d" cx="9.38" cy="5.2" r="4.82" />
                <path class="d" d="M.38,19.03C.38,14.05,4.41,10.02,9.38,10.02s9.01,4.03,9.01,9.01" />
            </g>
        </g>
    </g>
</svg>
</template>

<style>
.d {
    fill: none;
    stroke: #8b8b8b;
    stroke-miterlimit: 10;
    stroke-width: .75px;
}
</style>