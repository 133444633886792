<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.5 44">
        <polygon class="c"
            points="31.6 14.7 30.9 13.9 23.2 21.7 15.4 13.9 14.7 14.7 22.5 22.4 14.7 30.2 15.4 30.9 23.2 23.1 30.9 30.9 31.6 30.2 23.9 22.4 31.6 14.7" />
    </svg>
</template>

<style scoped>
    svg{
        cursor: pointer;
    }
    .b {
        fill: #fff;
    }

    .c {
        fill: #8b8b8b;
    }

</style>