<template>
    <div class="filters-wrapper">
        <FilterSection v-for="(filter, index) of filters" :key="filter.title" :items="filter.items"
            :title="filter.title" :type="filter.type" :expectedItems="filter.expectedItems"
            @toggle="toggleFilter($event, index)" />
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useProductStore } from "@/store/products";
// import { useCategoryStore } from "@/store/categories";
import { useFileTypeStore } from "@/store/filetypes";
import { useMarketingMaterialStore } from "@/store/marketingMaterial";
import FilterSection from "./filters/FilterSection.vue";
import { useScreen } from 'vue-screen'

const { nestedFileTypes } = storeToRefs(useFileTypeStore());
const { products } = storeToRefs(useProductStore());
// const { categories } = storeToRefs(useCategoryStore());
const { marketingMaterials } = storeToRefs(useMarketingMaterialStore());
const openedFilter = ref(undefined);
const screen = useScreen()
const emit = defineEmits(['toggle'])

const toggleFilter = (isOpen, index) => {
    if (isOpen) {
        openedFilter.value = index
    }
    else openedFilter.value = undefined
    emit('toggle', isOpen)
}

const filters = computed(() => [
    {
        items: products.value,
        title: 'ByCategory',
        type: 'categories',
        expectedItems: 9
    },
    {
        items: nestedFileTypes.value,
        title: 'ByFiletype',
        type: 'fileTypes',
        expectedItems: 10
    },
    {
        items: marketingMaterials.value,
        title: 'ByMarketingMaterial',
        type: 'marketingMaterials',
        expectedItems: 2
    },
]
    .filter((_filter, index) => screen.width >= 687 || index === openedFilter.value || openedFilter.value === undefined)
)
</script>
