<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.16 18.77">
        <g id="a" />
        <g id="b">
            <g id="c">
                <g>
                    <circle class="d" cx="7.11" cy="7.11" r="6.73" />
                    <line class="d" x1="11.55" y1="12.16" x2="17.89" y2="18.5" />
                </g>
            </g>
        </g>
    </svg>    
</template>

<style>
.d {
    fill: none;
    stroke: #8b8b8b;
    stroke-miterlimit: 10;
    stroke-width: .75px;
}
</style>