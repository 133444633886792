import WordPressAPIService from "@/services/WordPressService";
import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "../views/MainLayout.vue";
import Cookies from "js-cookie";

const routes = [
  {
    path: "/",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        meta: {
          title: {
            isTranlated: true,
            name: 'browse',
          }
        }
      },
      {
        path: "account",
        component: () => import("../views/MyAccount/MyAccount.vue"),
        children: [
          {
            path: "",
            name: "account",
            component: () => import("../views/MyAccount/MyAccountView.vue"),
            meta: {
              title: {
                isTranlated: true,
                name: 'account',
              }
            }
          },
        ],
      },
      {
        path: "projects",
        name: "projects",
        component: () => import("@/views/MyAccount/MyProjectsView.vue"),
        meta: {
          title: {
            isTranlated: true,
            name: 'projects',
          }
        }
      },
      {
        path: "projects/:project",
        name: "project",
        component: () => import("@/views/MyAccount/SingleProjectView.vue")
      },
      {
        path: "my-selection",
        name: "my-selection",
        component: () => import("../views/SelectionView.vue"),
        meta: {
          title: {
            isTranlated: true,
            name: 'my-selection',
          }
        }
      },
      {
        path: "models",
        name: 'resourcesModels',
        component: () => import("../views/ModelsView.vue"),
      },
      {
        path: "samples",
        component: () => import("../views/SamplesView.vue"),
      },
      {
        path: "muestras",
        component: () => import("../views/SamplesView.vue"),
      },
      {
        path: "categories/:categories+",
        component: () => import("../views/ModelView.vue"),
      },
      {
        path: "categories/:categories+/file-types/:fileTypes+",
        component: () => import("../views/ResourcesView")
      },
      {
        path: "categories/:categories+/marketing-materials/:marketingMaterials+",
        component: () => import("../views/ResourcesView")
      },
      {
        path: "categories/:categories+/file-types/:fileTypes+/marketing-materials/:marketingMaterials+/",
        component: () => import("../views/ResourcesView")
      },
      {
        path: "categories/:categories+/model/:model",
        name: 'resourcesModel',
        component: () => import("@/views/ResourcesView")
      },
      {
        path: "categories/:categories+/model/:model/galleries",
        name: 'resourcesModelGalleries',
        component: () => import("@/views/GalleriesView")
      },
      {
        path: "categories/:categories+/model/:model/galleries/:gallery",
        name: 'resourcesModelGallery',
        component: () => import("@/views/GalleryView")
      },
      {
        path: "models/file-types/:fileTypes+",
        component: () => import("../views/ModelsFileTypesView.vue"),
      },
      {
        path: "file-types/:fileTypes+",
        component: () => import("@/views/ResourcesView")
      },
      {
        path: "file-types/:fileTypes+/marketing-materials/:marketingMaterials+",
        component: () => import("@/views/ResourcesView")
      },
      {
        path: "marketing-materials/:marketingMaterials+",
        component: () => import("@/views/ResourcesView")
      },
      {
        path: "search/:terms+",
        name: "search",
        component: () => import("@/views/SearchView")
      },
      {
        path: "downloaded",
        name: "downloaded",
        component: () => import("@/views/DownloadedView")
      }
    ]
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: { requiresAuth: false },
  },


];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,
});


router.beforeEach((to) => {

  // We force the user to login if the route requires auth
  if (to.meta.requiresAuth && !WordPressAPIService.isLoggedIn()) {
    if (process.env.NODE_ENV === 'production') {
      console.log('Estamos en produccion')
      localStorage.removeItem('user');
      Cookies.remove('user');
      if(localStorage.getItem('logout') === 'logout'){
        console.log('Es logout, no redirigimos a login ')
        localStorage.removeItem('logout');
        window.location.replace(process.env.VUE_APP_LOGOUT);
      }
      else {
        console.log('No es logout, redirigimos a login ')
        window.location.replace(process.env.VUE_APP_LOGIN_URL_MODAL);
      }
    }
    else {
      router.push({ path: '/login' });
    }
  }

})

export default router;